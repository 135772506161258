import React, { useState, useEffect, useRef } from 'react';
import { calculateMedian, roundToThreeSigDigs } from './utils';
import './App.css';
import HeaderSidebar from './HeaderSidebar';
import Main from './Main';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:8000'
  : 'https://babbij.xyz';

function App() {
  const [question, setQuestion] = useState('');
  const [questionDisplay, setQuestionDisplay] = useState('');
  const [answer, setAnswer] = useState({ estimates: [], answer: null });
  const [loading, setLoading] = useState(false);
  const [responseList, setResponseList] = useState([]);
  const textAreaRef = useRef(null);
  const estimatesRef = useRef(new Set());

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!question.trim()) return;

    const currentQuestion = question;
    const postData = {
      question: currentQuestion,
      detail: "True",
      strength: 1,
      verbose: true,
      starterlist: answer?.estimates || []
    };

    if (currentQuestion !== questionDisplay) {
      setQuestionDisplay(currentQuestion);
      estimatesRef.current = new Set();
      setAnswer({ estimates: [], answer: null });
    }
    
    setLoading(true);

    try {
      const response = await fetch(`${API_BASE_URL}/api/estimate/stream`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
      const currentEstimates = [];

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        
        buffer += decoder.decode(value);
        const messages = buffer.split('\n\n');
        buffer = messages.pop() || '';
        
        for (const message of messages) {
          if (!message.trim() || !message.startsWith('data: ')) continue;
          
          try {
            const data = JSON.parse(message.slice(6));
            
            if (data.type === "update" && data.estimates) {
              const newEstimates = data.estimates.filter(estimate => {
                const key = `${estimate.model}-${estimate.output.estimate}`;
                if (!estimatesRef.current.has(key)) {
                  estimatesRef.current.add(key);
                  currentEstimates.push(estimate);
                  return true;
                }
                return false;
              });

              if (newEstimates.length > 0) {
                setAnswer(prevAnswer => {
                  const updatedEstimates = [...(prevAnswer?.estimates || []), ...newEstimates];
                  return {
                    estimates: updatedEstimates,
                    answer: calculateMedian(updatedEstimates.map(e => e.output.estimate))
                  };
                });
              }
            }
            
            if (data.type === "complete") {
              setLoading(false);
              setResponseList(prevList => {
                const questionIndex = prevList.findIndex(item => item.question === currentQuestion);
                const newResponse = {
                  question: currentQuestion,
                  estimates: currentEstimates,
                  answer: calculateMedian(currentEstimates.map(e => e.output.estimate))
                };
                
                if (questionIndex === -1) {
                  return [newResponse, ...prevList];
                }
                const newList = [...prevList];
                newList[questionIndex] = newResponse;
                return newList;
              });
            }
          } catch (e) {
            console.error('Error parsing SSE message:', e);
          }
        }
      }
    } catch (error) {
      console.error('Error submitting question:', error);
      setLoading(false);
    }
  };

  const handleEstimatesChange = (newEstimates) => {
    const medianValue = calculateMedian(newEstimates.map(e => e.output.estimate));
    
    setAnswer(prevAnswer => ({
      estimates: newEstimates,
      answer: medianValue
    }));

    // Update the responseList to reflect the new estimates
    setResponseList(prevList => {
      return prevList.map(item => {
        if (item.question === questionDisplay) {
          return {
            ...item,
            estimates: newEstimates,
            answer: medianValue
          };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [question]);

  return (
    <>
      <HeaderSidebar 
        responseList={responseList} 
        setAnswer={setAnswer}
        setQuestionDisplay={setQuestionDisplay} 
      />
      <Main 
        question={questionDisplay}
        responseDetail={answer}
        loading={loading}
        onEstimatesChange={handleEstimatesChange}
      />
      <div className="input-area">
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <textarea
              ref={textAreaRef}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
              placeholder="Estimate Anything"
              disabled={loading}
              className="input-textarea"
            />
            <button 
              type="submit" 
              disabled={loading}
              className="submit-button"
            >
              {loading ? '...' : 'Go'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default App;