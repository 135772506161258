export function calculateMedian(numbers) {
  if (!numbers || numbers.length === 0) return null;
  
  // Filter out any non-numeric values or undefined/null
  const validNumbers = numbers.filter(n => n !== null && n !== undefined && !isNaN(n));
  if (validNumbers.length === 0) return null;
  
  const sorted = [...validNumbers].sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

export function roundToThreeSigDigs(num) {
    if (num === null || num === undefined || isNaN(num)) return 'N/A';
    if (num === 0) return '0';

    const digits = Math.floor(Math.log10(Math.abs(num))) + 1;

    if (digits < 0) {
        const rounded = Number(num.toPrecision(3));
        if (rounded.toString().includes('e')) {
            return rounded.toExponential();
        } else {
            const decimalPlaces = Math.abs(digits) + 2;
            return rounded.toFixed(decimalPlaces);
        }
    } else {
        const factor = Math.pow(10, digits - 3);
        const rounded = Math.round(num / factor) * factor;
        return rounded.toLocaleString('en-US');
    }
}