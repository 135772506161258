import React, { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import NumberLine from './NumberLine';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;  // Remove padding here since MainContainer handles it
  max-width: 100%;
  box-sizing: border-box;
`;

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 0.75rem 0 0.75rem;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  box-sizing: border-box;

  @media (min-width: 768px) {
    margin-left: 280px;
    width: calc(100% - 280px);
    padding: 3.5rem 2rem 0 2rem;
  }
`;

// Remove padding from QuestionText since MainContainer handles it
const QuestionText = styled.p`
  width: 100%;
  text-align: center;
  margin: 1rem 0;
  font-size: 1.2rem;
`;




const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const Spinner = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`;

function Main({question, responseDetail, loading, onEstimatesChange}) {
  const handleEstimatesUpdate = useCallback((newEstimates) => {
    if (onEstimatesChange) {
      onEstimatesChange(newEstimates);
    }
  }, [onEstimatesChange]);

  return (
    <MainContainer>
      <QuestionText>{question}</QuestionText>
      <ContentContainer>
        {responseDetail?.estimates?.length > 0 && (
          <NumberLine 
            responseDetail={responseDetail}
            onEstimatesChange={handleEstimatesUpdate} 
          />
        )}
        {loading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {!loading && (!responseDetail?.estimates || responseDetail.estimates.length === 0) && (
          <NumberLine responseDetail={null} />
        )}
        <br/>
        <br/>
        <br/>
        <br/>
      </ContentContainer>
    </MainContainer>

  );
}

export default Main;