import React, { useState, useEffect, useCallback } from 'react';
import { calculateMedian, roundToThreeSigDigs } from './utils';

function HeaderSidebar({ responseList, setAnswer, setQuestionDisplay }) {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const updateState = (item) => {
    // Ensure we're passing the complete estimate structure
    setAnswer({
      estimates: item.estimates,
      answer: item.answer
    });
    setQuestionDisplay(item.question);
    if (isMobile) {
      setIsSidebarVisible(false);
      setIsOverlayVisible(false);
    }
  };

  const handleResize = useCallback(() => {
    const mobile = window.innerWidth < 768;
    setIsMobile(mobile);

    if (mobile) {
      setIsSidebarVisible(false);
      setIsOverlayVisible(false);
    } else {
      setIsOverlayVisible(false);
      setIsSidebarVisible(true);
    }
  }, []);

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const toggleSidebar = useCallback(() => {
    setIsSidebarVisible((prev) => !prev);
    if (isMobile) {
      setIsOverlayVisible((prev) => !prev);
    }
  }, [isMobile]);

  const closeSidebar = () => {
    if (isMobile) {
      setIsSidebarVisible(false);
      setIsOverlayVisible(false);
    }
  };

  const handleClickOutside = useCallback((event) => {
    if (window.innerWidth < 768) {
      const menuToggle = document.getElementById('menu-toggle');
      const isClickInsideMenuToggle = menuToggle && menuToggle.contains(event.target);
      const sidebar = document.getElementById('sidebar');
      const isClickInsideSidebar = sidebar && sidebar.contains(event.target);

      if (!isClickInsideSidebar && !isClickInsideMenuToggle && isSidebarVisible) {
        setIsSidebarVisible(false);
        setIsOverlayVisible(false);
      }
    }
  }, [isSidebarVisible]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <>
      <header>
        <div className="header-content">
          <button 
            id="menu-toggle" 
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling
              toggleSidebar();
            }}
          >
            ☰
          </button>
          <div className="logo">babbij</div>
          <div className="spacer"></div>
        </div>
      </header>
      <div 
        className={`overlay ${isOverlayVisible ? 'active' : ''}`} 
        onClick={closeSidebar}
      />
      <nav 
        id="sidebar" 
        style={{ 
          left: isSidebarVisible ? '0px' : '-280px',
          transition: 'left 0.3s ease-in-out'
        }}
      >
        <ul>
          {responseList && responseList.map((item, index) => (
          <li key={index}>
          <div 
            className="list-item" 
            onClick={() => updateState(item)}
            style={{ cursor: 'pointer' }}
          >
            <div>{item.question}</div>
            <p style={{ textAlign: 'right' }}>
              {roundToThreeSigDigs(calculateMedian(item.estimates.map(e => e.output.estimate)))}
            </p>
          </div>
        </li>
          ))}
        </ul>
      </nav>
    </>
  );
}

export default HeaderSidebar;