import React, { useState, useMemo } from 'react';
import './NumberLine.css';
import { roundToThreeSigDigs } from './utils';
import styled from 'styled-components';

const DescriptionBox = styled.div`
  position: relative;
  padding-bottom: 2rem;
`;

const ModelName = styled.div`
  color: #808080;
  font-size: 0.9rem;
  font-style: italic;
  ${props => props.inDescription ? `
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  ` : `
    display: block;
    margin: 0.5rem 0;
  `}
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  &:hover {
    color: #666;
  }
`;

const DeleteButton = styled.button`
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  &:hover {
    background-color: #cc0000;
  }
`;

const NumberLine = ({ responseDetail, onEstimatesChange }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [localEstimates, setLocalEstimates] = useState(responseDetail?.estimates || []);

  React.useEffect(() => {
    if (responseDetail?.estimates) {
      setLocalEstimates(responseDetail.estimates);
    }
  }, [responseDetail?.estimates]);

  const handleDeleteEstimate = (estimateToDelete) => {
    const newEstimates = localEstimates.filter(est => 
      !(est.model === estimateToDelete.model && 
        est.output.estimate === estimateToDelete.output.estimate)
    );
    setLocalEstimates(newEstimates);
    setSelectedEstimate(null);
    if (onEstimatesChange) {
      onEstimatesChange(newEstimates);
    }
  };

  const { estimates, percentileData } = useMemo(() => {
    if (!localEstimates?.length) {
      return { estimates: [], percentileData: null };
    }

    const validEstimates = localEstimates.filter(
      est => est?.output?.estimate != null && !isNaN(est.output.estimate)
    );

    if (validEstimates.length === 0) {
      return { estimates: [], percentileData: null };
    }

    const sortedEstimates = [...validEstimates].sort((a, b) => 
      a.output.estimate - b.output.estimate
    );

    const len = sortedEstimates.length;
    const q1Index = Math.floor(len * 0.25);
    const medianIndex = Math.floor(len * 0.5);
    const q3Index = Math.floor(len * 0.75);

    const min = sortedEstimates[0].output.estimate;
    const max = sortedEstimates[len - 1].output.estimate;

    return {
      estimates: sortedEstimates,
      percentileData: {
        q1: sortedEstimates[q1Index] || sortedEstimates[0],
        median: sortedEstimates[medianIndex] || sortedEstimates[0],
        q3: sortedEstimates[q3Index] || sortedEstimates[0],
        min,
        max,
        count: len,
        allEstimates: sortedEstimates
      }
    };
  }, [localEstimates]);

  if (!responseDetail?.estimates?.length) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1>Estimate Anything</h1>
        <p>Ask any question with a numerical answer</p>
        <br />
        <img src={`${process.env.PUBLIC_URL}/logo_no_background.png`} alt="Example" style={{ width: '30px', height: 'auto' }} />
        <br />
        <br />
        <br />
        <h3>"How much revenue (in USD) will LLMs generate in 2030?"</h3>
        <br />
        <h3>"How many ping pong balls fit on a 747?"</h3>
        <br />
        <h3>"What is the market size of solenoids in the US (in USD)?"</h3>
        <br />
        <h3>"How many alien civilizations live within 1,000 lightyears of earth?"</h3>
      </div>
    );
  }

  if (!percentileData) {
    return (
      <div style={{ width: '100%', textAlign: 'center', marginTop: '2rem' }}>
        <p>Collecting estimates... ({responseDetail.estimates.length} received)</p>
      </div>
    );
  }

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1><b>{roundToThreeSigDigs(percentileData.median.output.estimate)}</b></h1>
      <br/>
      <h2>
        ({roundToThreeSigDigs(percentileData.q1.output.estimate)} &nbsp; &mdash; &nbsp;
        {roundToThreeSigDigs(percentileData.q3.output.estimate)})
      </h2>
      <p>Estimates received: {percentileData.count}</p>
      
      <div className="visualization-container">
        {/* Balls container */}
        <div className="dots-container">
          {percentileData.allEstimates.map((estimate, index) => {
            const EDGE_PADDING = 10; // percentage from edges
            const position = EDGE_PADDING + ((estimate.output.estimate - percentileData.min) / 
                            (percentileData.max - percentileData.min)) * (100 - 2 * EDGE_PADDING);
            
            const isQuartile = [percentileData.q1, percentileData.median, percentileData.q3].some(
              q => q.output.estimate === estimate.output.estimate
            );

            return (
              <div 
                key={index}
                className="estimate-dot"
                style={{
                  left: `${position}%`,
                  width: isQuartile ? '20px' : '12px',
                  height: isQuartile ? '20px' : '12px',
                  backgroundColor: activeIndex === index ? '#2980b9' : (isQuartile ? 'lightgray' : '#A9A9A9'),
                  border: `1px solid ${isQuartile ? "orange" : "gray"}`,
                }}
                onMouseEnter={() => setActiveIndex(index)}
                onMouseLeave={() => setActiveIndex(-1)}
                onClick={() => setSelectedEstimate(estimate)}
              >
                {activeIndex === index && (
                  <div className="estimate-tooltip">
                    {roundToThreeSigDigs(estimate.output.estimate)}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* Line with ticks */}
        <div className="line-container">
          <div className="number-line">
            <div className="arrow left-arrow" />
            <div className="line" />
            <div className="arrow right-arrow" />
            <div className="tick min-tick" />
            <div className="tick mid-tick" />
            <div className="tick max-tick" />
          </div>
        </div>
      </div>

      {selectedEstimate && (
        <ModalOverlay onClick={() => setSelectedEstimate(null)}>
          <ModalContent onClick={e => e.stopPropagation()}>
  <CloseButton onClick={() => setSelectedEstimate(null)}>×</CloseButton>
  <div>
    <h2>Estimate: {roundToThreeSigDigs(selectedEstimate.output.estimate)}</h2>
    <ModelName inDescription={false}>{selectedEstimate.model}</ModelName>
    <DeleteButton onClick={() => handleDeleteEstimate(selectedEstimate)}>
      Delete Estimate
    </DeleteButton>
    <p>{selectedEstimate.output.explanation}</p>
              {selectedEstimate.output.source && selectedEstimate.output.source.length > 0 && (
                <div>
                  <p>Sources:</p>
                  <div className="source-links-wrapper">
                    <ul>
                      {selectedEstimate.output.source.map((link, i) => (
                        <li key={i}>
                          <a href={link} target="_blank" rel="noopener noreferrer">
                            {link}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

            </div>
            
       
            <br/>
            <br/>
            <br/>
            <br/>
          </ModalContent>

        </ModalOverlay>
      )}
      
      {[
        { estimate: percentileData.median, label: "Mid" },
        { estimate: percentileData.q1, label: "Low" },
        { estimate: percentileData.q3, label: "High" }
      ].map((item, index) => (
        <DescriptionBox key={index} className='description'>
          <ModelName inDescription={true}>{item.estimate.model}</ModelName>
          <h2>{item.label}: {roundToThreeSigDigs(item.estimate.output.estimate)}</h2>
          <p>{item.estimate.output.explanation}</p>
          {item.estimate.output.source && item.estimate.output.source.length > 0 && (
            <div>
              <p>Sources:</p>
              <div className="source-links-wrapper">
                <ul>
                  {item.estimate.output.source.map((link, i) => (
                    <li key={i}>
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <br/>
          <hr/>
        </DescriptionBox>
      ))}
    </div>
  );
};

export default NumberLine;